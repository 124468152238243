import { useEffect, useState } from "react";
import { SpeechRecognition } from "./speech";


const SRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

class SpeechToText {
  private recognition: SpeechRecognition;
  listening = false;

  static isSupported() {
    return !!SRecognition;
  }

  constructor(
    lang = 'en-US',
  ) {
    if (!SRecognition) {
      throw new Error('Speech recognition is not supported in this browser');
    }
    this.recognition = new SRecognition() as SpeechRecognition;
    this.recognition.lang = lang;
  }

  start() {
    if (!SpeechToText.isSupported()) {
      console.error('Speech recognition is not supported in this browser');
      return;
    }
    this.listening = true;
    this.recognition.start();
  }

  onStart(callback: () => void) {
    this.listening = true;
    this.recognition.onaudiostart = callback
  }

  onEnd(callback: () => void) {
    this.listening = false;
    this.recognition.onaudioend = callback;
  }

  onError(callback: (error: string) => void) {
    this.recognition.onerror = (event) => {
      callback(event.error);
    };
  }

  stop() {
    this.recognition.stop();
    this.listening = false;
  }

  onResult(callback: (result: string) => void) {
    this.recognition.onresult = (event) => {
      console.log(event.results);
      const result = event.results[event.results.length - 1][0].transcript;
      this.listening = false;
      callback(result);
    };
  }
}

const speechToText = SpeechToText.isSupported() ? new SpeechToText() : null;

export const useSpeechToText = () => {
  const [transcript, setTranscription] = useState<string | null>(null);
  const [listening, setListening] = useState(speechToText?.listening);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!SpeechToText.isSupported()) {
      setError("Speech recognition is not supported in this browser");
      return;
    }

    speechToText?.onStart(() => {
      setError(null);
      setTranscription(null);
      setListening(true);
    });

    speechToText?.onResult((result) => {
      setTranscription(result);
      setError(null);
    });

    speechToText?.onError((error) => {
      setError(error);
      setTranscription(null);
      setListening(false);
    });

    speechToText?.onEnd(() => {
      setError(null);
      setTranscription(null);
      setListening(false);
    });
  }, []);

  /**
   * Initiates the speech-to-text process by starting the speech recognition service.
   */
  function startListening() {
    speechToText?.start();
  }

  /**
   * The function `stopListening` stops the speech-to-text functionality.
   */
  function stopListening() {
    speechToText?.stop();
  }

  return { text: transcript, error, startListening, stopListening, isListening: listening };
}